<template>
  <div class="renewal-modal">
    <a-modal
      v-model="unbindModal"
      title="解绑"
      :maskClosable="false"
      width="1400px"
      destroyOnClose
      @ok="unbindSubmit"
      @cancel="handleModalCancel"
    >
      <a-table
        rowKey="sequenceNbr"
        :columns="columns"
        :data-source="tableData"
        :scroll="{ x: 'max-content' }"
        :pagination="false"
        :loading="tableLoading"
        :rowClassName="
          (record, index) => {
            if (5 - Number(record.extend2) == 0) {
              return 'rowgray';
            }
          }
        "
      >
        <template slot="sortNo" slot-scope="text">
          {{ text }}
        </template>
        <template slot="userType" slot-scope="text">
          {{ text == '1' ? '个人用户' : '企业用户' }}
        </template>
        <template slot="user" slot-scope="text, record">
          {{ record.callUserName }}/{{ record.tel }}
        </template>
        <!-- <template slot="propductName"> 计价软件 </template> -->
        <template slot="status" slot-scope="text, record">
          {{
            5 - Number(record.unbindCount) >= 0
              ? 5 - Number(record.unbindCount)
              : 0
          }}次
        </template>

        <template slot="action" slot-scope="text, record">
          <a-button @click="clean(record)">移除</a-button>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  deleteHardwareapplySerials,
  examine,
  hardwareapplySerials,
} from '@/api/lock';

export default Vue.extend({
  name: 'add-modal',
  props: {
    unbindModal: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableLoading: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'sortNo',
          width: '100px',
        },
        // {
        //   title: '序列',
        //   dataIndex: 'sequenceNbr',
        //   width: '200px',
        // },
        {
          title: '加密锁业务号',
          dataIndex: 'busId',
        },
        {
          title: '用户类型',
          dataIndex: 'enterprise.userType',
          scopedSlots: { customRender: 'userType' },
        },
        {
          title: '企业名称',
          dataIndex: 'enterprise.name',
        },
        {
          title: '联系人',
          dataIndex: 'enterprise.callUserName',
        },
        {
          title: '联系电话',
          dataIndex: 'enterprise.tel',
        },

        {
          title: '员工信息',
          dataIndex: 'user',
          scopedSlots: { customRender: 'user' },
        },
        // {
        //   title: '产品名称',
        //   dataIndex: 'propductName',
        //   scopedSlots: { customRender: 'propductName' },
        // },
        {
          title: '绑定时间',
          dataIndex: 'bindTime',
        },
        {
          title: '绑定设备名称',
          dataIndex: 'machineName',
        },
        {
          title: '设备序列号',
          dataIndex: 'machineId',
        },
        {
          title: '剩余解绑次数',
          dataIndex: 'status',
          width: '120px',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
    };
  },
  methods: {
    handleModalCancel() {
      this.$emit('update:unbindModal', false);
    },
    clean(record) {
      this.tableData = this.tableData.filter((e) => {
        return e.sequenceNbr != record.sequenceNbr;
      });
      this.tableData.map((item, index) => (item.sortNo = index + 1));

      /*  deleteHardwareapplySerials(record.serial,0).then((res)=>{
        console.log("移除成功");
      });*/
    },
    unbindSubmit(row, type) {
      let data = this.tableData;
      if (data.length > 0) {
        let arr = [];
        for (let i = 0; i < data.length; i++) {
          if (!data[i].machineId) {
            return this.$message.error('未绑定不能解绑');
          }
          if (Number(data[i].extend2) >= 5) {
            return this.$message.error('解绑次数不足');
          }
          arr.push({
            serial: data[i].serial,
            applyType: 1,
          });
        }
        examine(arr).then((res) => {
          if (res.status === 200 && res.result) {
            this.$message.success('解绑成功');
            this.$emit('onSuccess');
            setTimeout(() => {
              this.$emit('update:unbindModal', false);
            }, 1000);
          }
        });
      }
    },
  },
});
</script>

<style>
.rowgray td {
  opacity: 0.4 !important;
}
</style>
