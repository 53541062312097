/*
 * @Descripttion:
 * @Author: liuxia
 * @Date: 2023-10-24 09:40:47
 * @LastEditors: wangru
 * @LastEditTime: 2023-10-27 15:24:38
 */
import http from '@/utils/http';

// 保存个人/企业信息
export function hardwareSave(formData): any {
  return http.post(
    `/hardware-resources/1/hardware/hardware_enterprise/save`,
    formData
  );
}

// 查询企业信息
export function hardwareEnterprise(fromdata): any {
  return http.get(
    `/hardware-resources/1/hardware/hardware_enterprise`,
    fromdata
  );
}

// 加密狗列表
export function hardwarepool(formData): any {
  return http.get(`/hardware-resources/1/hardware/hardwarepool`, formData);
}
// 加密狗列表
export function hardwarepoolGroupStatus(): any {
  return http.get(`/hardware-resources/1/hardware/hardwarepoolGroupStatus`);
}



// 加密狗申请审核 （续订 解绑 挂失）
export function examine(formData): any {
  return http.post(
    `/hardware-resources/1/hardware/hardwareapply/examine`,
    formData
  );
}

export function singleexamine(formData): any {
  return http.post(
    `/hardware-resources/1/hardware/hardwareapply/singleexamine`,
    formData
  );
}

export function examinelost(formData): any {
  return http.post(
    `/hardware-resources/1/hardware/hardwareapply/examinelost`,
    formData
  );
}

// 加密狗申请审核 （续订 解绑 挂失）
export function hardwareapplyPage(formData): any {
  return http.get(
    `/hardware-resources/1/hardware/hardwareapply/page`,
    formData
  );
}

export function hardwareapplySerials(formData, status): any {
  return http.post(
    `/hardware-resources/1/hardware/hardwareapply/byserials/` + status,
    formData
  );
}

export function deleteHardwareapplySerials(serial, status): any {
  return http.get(
    `/hardware-resources/1/hardware/hardwareapply/delete/` +
      serial +
      '/' +
      status
  );
}

// 加密狗详情页
export function infoDetail(id): any {
  return http.get(`/hardware-resources/1/hardware/hardwarepool/info/${id}`);
}

export function getHardWarepoolInitStatus(data): any {
  return http.post(
    `/hardware-resources/1/hardware/hardwarepool/initcammandstatus`,
    data
  );
}

// 加密狗详情页
export function hardWarepoolAdd(formData): any {
  return http.post(`/hardware-resources/1/hardware/hardwarepool/add`, formData);
}

// 加密狗kefu
export function hardwarekefu(searchForm): any {
  return http.get(`/hardware-resources/1/hardware/hardwarekefu`,searchForm);
}
// 加密狗kefu
export function hardwareHandler(id, status,desc): any {
  return http.post(
    `/hardware-resources/1/hardware/hardwarekefu/handle/` + id + `/` + status,{extend3:desc}
  );
}
